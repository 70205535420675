@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';

$theme-colors: (
  'primary': $sk-primary,
);
@media (max-width: 1320px) and (min-width: 1200px) {
  .col-xl-8 {
    flex: 0 0 60.66667% !important;
    max-width: 60.66667% !important;
  }
  .col-xl-4 {
    flex: 0 0 39.33333% !important;
    max-width: 39.33333% !important;
  }
}
$grid-breakpoints: $sk-bt-breakpoint;

//----Typography----//
$h1-font-size: 2em;
$h2-font-size: 1.5em;
$h3-font-size: 1.17em;
$h4-font-size: 1em;
$h5-font-size: 0.83em;
$h6-font-size: 0.67em;
$headings-margin-bottom: 0.5rem;
$headings-font-weight: bold;

@import '~bootstrap/scss/bootstrap';
