//----Loader----//
.sk-api-loader-ctnr {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  @include center-all;
}
.sk-api-loader {
  .MuiCircularProgress-svg {
    color: #ffffff;
  }
}
