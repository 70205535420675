$ff-OpenSans: 'Open Sans', sans-serif, color-emoji;
$ff-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

//colors
$sk-red: #e61d17;
$sk-black: #000000;
$sk-black-label: #3e4659;
$sk-gray: #d2d2d2;
$sk-dark-gray: #9c9c9c;

$sk-primary: #e61d17;
$sk-btn-primary: #ec4747;
$sk-primary-hover: #d4100a;
$sk-primary-light: #f26b5b;

//breakpoints value
$sk-bp-xs: 0px; //--extra small
$sk-bp-ms: 321px; //--xs small mobile
$sk-bp-mm: 376px; //--xs medium mobile
$sk-bp-ml: 426px; //--xs large mobile
$sk-bp-sm: 576px; //--small
$sk-bp-md: 768px; //--medium
$sk-bp-lg: 992px; //--large
$sk-bp-xl: 1200px; //--extra large

//bootstrap breakpoints
$sk-bt-breakpoint: (
  xs: $sk-bp-xs,
  sm: $sk-bp-sm,
  md: $sk-bp-md,
  lg: $sk-bp-lg,
  xl: $sk-bp-xl,
);
