//----404 Page----//
.sk-404-main-ctnr {
  min-height: 0;
}
.sk-404-ttl {
  font-size: 40px;
  color: var(--sk-p-red);
  line-height: 106px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  margin-top: 50px;
  @include media-below(md) {
    margin-top: 50px;
    font-size: 28px;
    line-height: 44px;
  }
}
.sk-404-ilus-ctnr {
  width: 100%;
  margin-top: 40px;
  position: relative;
  @include center-all;
}
.sk-404-ilus {
  width: 600px;
  @include noselect;
  @include media-below(md) {
    width: 400px;
  }
  @include media-below(sm) {
    width: 300px;
  }
}
.sk-404-btn-ctnr {
  a {
    text-decoration: none;
  }
  @include center;
  margin-top: 90px;
  margin-bottom: 50px;
  .sk-404-btn {
    color: #d2d2d2;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #d2d2d2;
  }
}

.button-link {
  text-decoration: none !important;
}

//----------Form Container--------//
.formcontainer {
  // padding: 10px 25px;
  max-width: 460px;
}

//---------Form Input Label-----------//
.sk-up-ob-pf-form-lbl {
  font-size: 12px;
  width: 100%;
  // line-height: 19px;
  margin-bottom: 13px;
  font-family: 'Open Sans';
  font-style: normal;
  // color: var(--sk-black-labe);
  color: #3e4659;
  font-weight: 600;
  .right-text-light {
    float: right;
    font-size: 12px;
    color: rgb(175, 175, 175);
  }
}
.sk-red-txt {
  color: var(--sk-primary);
}
.sub-label {
  color: var(--sk-dark-gray) !important;
}

//-------Form Action Button ---------//
.form-action-button {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  // max-width: 400px !important;
  height: 48px;
  background-color: #ec4747 !important;
  span {
    font-size: 15px;
  }
}

//---------Divider with text ---------//
.separator {
  display: flex;
  align-items: center;
  font-size: 14px;
  // margin-top: 20px;
  text-align: center;
  color: #69778c;
}
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dbdeeb;
}
// .separator::before {
//   margin-right: 0.25em;
// }
// .separator::after {
//   margin-left: 0.25em;
// }
.cursor-p {
  cursor: pointer;
}
//---------LinkedIn Button ---------//
.ln-btn {
  height: 44px;
  max-width: 400px;
  background-color: rgb(0, 119, 181);
  cursor: pointer;
  border-radius: 4px;
  .ln-btn-img {
    width: 52px;
    background-color: rgb(9, 108, 159);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    img {
      width: 13.7px;
      height: 13.7px;
    }
  }
  .ln-btn-text {
    text-transform: capitalize;
    color: white;
    font-size: 14px;
  }
}

//---------Google Button ------------//
.gl-btn {
  background-color: #ffffff;
  // border: 1px solid #3486f7;
  // border-radius: 3px;
  // color: #000000;
  height: 48px;
  // letter-spacing: .2px;
  text-align: center;
  // font-size: 14px;
  display: block;
  cursor: pointer;
  margin-top: 40px;
  user-select: none;
  min-width: 380px;
  @include media-below(md) {
    min-width: 0;
  }
  .div-img {
    width: 48px;
    height: 48px;
    text-align: center;
    display: flex;
  }
  .ln-btn-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    /* Black */

    color: #000000;
  }
  border: 1px solid #dbdeeb;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(10, 4, 60, 0.09);
  border-radius: 4px;
}
//----------Media Query --------------//
@include media-below(lg) {
  .formcontainer {
    // padding: 10px 10px;
  }
  .form-action-button {
    margin-top: 22px !important;
  }
}

//---- company thank you page ----//
.thank-you-card {
  width: 100%;
  max-width: 548px;
  margin: 20px 40px;
  border-radius: 8px !important;
  box-shadow: 1px 6px 16px 0px rgba(0, 0, 0, 0.12) !important;
  .MuiButtonBase-root {
    font-weight: 600;
    box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiButtonBase-root:hover {
    box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.12);
  }
}

.thanku-graphic-icon {
  margin-top: 20px;
  @include media-below(md) {
    width: 216px;
  }
}

.sk-thanku-heading {
  margin-top: 20px;
  font-weight: 700;
  font-size: 40px;
  color: var(--sk-primary);
  @include media-below(md) {
    font-size: 30px;
  }
}

.sk-thanku-text {
  font-size: 16px;
  font-weight: 600;
}

.sk-jp-action {
  margin: 0px 15px;
  justify-content: center;
  @include media-below(md) {
    margin: 0px 0px;
  }
}
//------------------------------//

//-----------OTP Input----------//
.sk-otp-heading {
  margin-top: 20px;
  font-weight: 600;
  font-size: 30px;
  color: var(--sk-primary);
  @include media-below(md) {
    font-size: 20px;
  }
}

.sk-otp-text {
  font-size: 12px;
  font-weight: 500;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
//---------ReCApcha design -----//
#recaptcha-privacy {
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  bottom: 2px;
  right: 5px;
}

//--------Accept term and policy----------//
.sk-tc-accpt-ctnr {
  .sk-tc-accpt-txt {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #69778c;
    a {
      text-decoration: underline;
      color: #000000;
    }
  }
  .MuiSvgIcon-root {
    height: 26px !important;
    width: 26px !important;
  }
}

.sk-title {
  // font-size: 24px;
  // font-weight: 600;
  // position: absolute;
  // height: 41px;
  // left: 0%;
  // right: 0%;
  // top: calc(50% - 41px/2 - 362.5px);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
}
.sk-sub-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #2d3748;
  // font-size: 16px;
  // color: #69778c;
  margin-top: 20px;
}

.sk-otp-input {
  width: 60px !important;
  height: 48px;
  margin: 0 10px;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid #cccfd9;
  @include media-below(md) {
    width: 35px !important;
    height: 40px;
  }
}

.sk-back-btn {
  top: 50px;
  left: 30px;
  @media (min-width: '1610px') {
    left: -50px;
  }
  @media (max-width: '750px') {
    top: -76px;
  }
}

.left-panel-text {
  position: absolute;
  width: 247px;
  height: 135px;
  left: 40px;
  bottom: 120px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.5px;
}
.MuiButton-containedPrimary {
  font-weight: 600;
}

.error-page-illus {
  width: 450px;
  margin: auto;
  margin-top: 135px;
  @include center-all;
  .error-icon {
    width: 120px;
    height: 120px;
  }
}

.sk-error-ttl {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  /* identical to box height */

  color: #000000;
  text-align: center;
  margin-top: 76px;
}

.sk-error-sub-ttl {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  /* Dark Grey */
  margin-top: 15px;
  color: #3e4659;
}
.global-tag-line {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #000000;
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
  // border-color: #DBDEEB !important;
  //   border-style: solid !important;
  //   border-width: 1px !important;
  //   border-radius: 5px !important;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
